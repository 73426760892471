<template>
  <div class="change-pricelist-confirm">
    <div class="change-pricelist-confirm__title medium-title">
      {{ `${$t('newpricelist')}: ${newpricelist}` }}
    </div>
    <div class="change-pricelist-confirm__desc standart-text">
      {{ $t('desc') }}
    </div>
    <div class="change-pricelist-confirm__desc standart-text">
      <!--      {{ newFeatures ? $t('newfeatures') : $t('warning') }}-->
      {{ $t('warning') }}
    </div>
    <ul class="change-pricelist-confirm__info standart-text">
      <li v-for="(item, i) in infoList" :key="i" class="change-pricelist-confirm__item">
        <div class="change-pricelist-confirm__item-title">
          {{ item.title }}
        </div>
        <div class="change-pricelist-confirm__item-value">
          {{ item.value }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNaN, isDate } from 'lodash';
export default {
  name: 'ChangePricelistConfirm',
  props: {
    oldpricelist: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      default: () => {},
    },
    func: {
      type: String,
      default: '',
    },
    newpricelist: {
      type: String,
      required: true,
    },
    newFeatures: {
      type: Boolean,
      default: false,
    },
    oldprice: {
      type: [String, Number],
      required: true,
      validator: val => !isNaN(parseFloat(val)),
    },
    newprice: {
      type: [String, Number],
      required: true,
      validator: val => !isNaN(parseFloat(val)),
    },
    // eslint-disable-next-line
    oldexpiredate: {
      type: [String, Number, Date],
      validator: val => isDate(new Date(val)),
    },
    // eslint-disable-next-line
    newexpiredate: {
      type: [String, Number, Date],
      validator: val => isDate(new Date(val)),
    },
  },
  computed: {
    infoList() {
      return !this.oldexpiredate && !this.newexpiredate
        ? [
            {
              title: this.$t('oldprice'),
              value: this.$n(parseFloat(this.oldprice), 'currency'),
            },
            {
              title: this.$t('newprice'),
              value: this.$n(parseFloat(this.newprice), 'currency'),
            },
            {
              title: this.$t('diffprice'),
              value: this.$n(parseFloat(this.model.diffprice), 'currency'),
            },
          ]
        : [
            {
              title: this.$t('oldexpiredate'),
              value: this.$d(new Date(this.oldexpiredate), 'short'),
            },
            {
              title: this.$t('newexpiredate'),
              value: this.$d(new Date(this.newexpiredate), 'short'),
            },
            {
              title: this.$t('oldprice'),
              value: this.$n(parseFloat(this.oldprice), 'currency'),
            },
            {
              title: this.$t('newprice'),
              value: this.$n(parseFloat(this.newprice), 'currency'),
            },
          ];
    },
  },
};
</script>

<!--    "desc": "При смене тарифа будет пересчитана дата окончания действия услуги. {br}Дата изменится пропорционально стоимости тарифов.",-->
<i18n>
{
  "ru": {
    "desc": "При смене тарифного плана дата окончания действия услуг не изменяется.  Стоимость перехода рассчитывается до даты окончания услуги.",
    "newfeatures": "Новые ресуры на сервере применяются после зачислеия оплаты в течении нескольких минут.",
    "warning": "ВНИМАНИЕ! После поступления средств для применения параметров нового плана нужна остановка сервера на 5 минут.",
    "newpricelist": "Новый тарифный план",
    "oldpricelist": "Старый тарифный план",
    "oldexpiredate": "Текущая дата окончания",
    "newexpiredate": "Новая дата окончания",
    "oldprice": "Стоимость на текущем тарифе",
    "newprice": "Стоимость на новом тарифе",
    "diffprice": "Стоимость перехода на новый тариф"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.change-pricelist-confirm {
  &__info {
    no-style-list();
  }
  &__desc {
    margin-bottom: 0.5em;
  }

  &__title {
    margin-bottom: 0.75em;
  }
  &__item {

    & + & {
      margin-top: 0.5em;
    }
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    &-title {
      +breakpoint(sm-and-up) {
        flex: 0 0 50%;
        margin-right: 1.5rem;
      }
    }

    &-value {
      font-weight: $font-weight-medium;
    }
  }
}
</style>
