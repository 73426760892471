import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import { monthRuToNumber } from '@/utils/parsers/dateParser';
export default {
  mixins: [billMgrToolActions, getLocalizedPeriod],
  computed: {
    textProlongOn() {
      return this.$t(`${this.isPast ? 'wasActiveUnder' : 'isActiveUnderOn'}`, {
        date: this.$d(this.tariff.expiredate, 'short'),
        prolong: this.tariff.autoprolong_name,
        cost: this.costAutoProlong,
      });
    },
    promiseOn() {
      return this.$t(`${this.tariff.promise !== 'null' ? 'promise' : ''}`, {
        promise: this.formDataPromiseTariff,
      });
    },
    promiseOnItem() {
      return this.$t(`${this.item.promise !== 'null' ? 'promise' : ''}`, {
        promise: this.formDataPromiseItem,
      });
    },
    formDataPromiseTariff() {
      let arr = this.tariff.promise.split('-');
      let day = arr[2];
      let month = arr[1];
      let year = arr[0];
      let dataPromise = day + '.' + month + '.' + year;
      return dataPromise;
    },
    formDataPromiseItem() {
      let arr = this.item.promise.split('-');
      let day = arr[2];
      let month = arr[1];
      let year = arr[0];
      let dataPromise = day + '.' + month + '.' + year;
      return dataPromise;
    },
    isPastAndNeedBalance() {
      if (this.balance !== 0 && +this.balance > +this.tariff.item_cost) {
        return (
          Date.now() / (24 * 60 * 60 * 1000) - this.tariff.expiredate / (24 * 60 * 60 * 1000) > 0
        );
      }
    },
    textProlongOff() {
      return this.$t(`${this.isPast ? 'wasActiveUnder' : 'isActiveUnderOff'}`, {
        date: this.$d(this.tariff.expiredate, 'short'),
        cost: this.tariff.cost.toFixed(2),
      });
    },
  },
  methods: {
    getCost() {
      const params = {
        id: this.tariff.id,
        func: 'service.prolong',
      };
      this.fetchBillMgrAction(params).then(data => {
        const { slist } = data;
        if (slist) {
          this.period = slist.period.map(i => {
            return {
              ...i,
              cost: parseFloat(i.cost).toFixed(2),
              label: this.getLocalizedPeriod(i.k),
            };
          });
          if (this.isAutoProlong && this.tariff.autoprolong_period !== 'null') {
            // console.log(this.isAutoProlong, this.item.autoprolong_period);
            // console.log(this.period.filter(i => i.k === this.item.autoprolong_period)[0].cost);
            return (this.costAutoProlong = this.period.filter(
              i => i.k === this.tariff.autoprolong_period
            )[0].cost);
          } else if (this.isAutoProlong && this.tariff.autoprolong_period === 'null') {
            // console.log('test OK');
            // console.log(this.isAutoProlong, this.item.autoprolong_period);
            // console.log(this.period[0].cost);
            return (this.costAutoProlong = this.period[0].cost);
          }
        } else {
          throw new Error(this.$t('prolong.error'));
        }
      });
    },
    getCostTable() {
      const params = {
        id: this.item.id,
        func: 'service.prolong',
      };
      this.fetchBillMgrAction(params).then(data => {
        const { slist } = data;
        if (slist) {
          this.period = slist.period.map(i => {
            return {
              ...i,
              cost: parseFloat(i.cost).toFixed(2),
              label: this.getLocalizedPeriod(i.k),
            };
          });
          if (this.isAutoProlong && this.item.autoprolong_period !== 'null') {
            // console.log(this.isAutoProlong, this.item.autoprolong_period);
            // console.log(this.period.filter(i => i.k === this.item.autoprolong_period)[0].cost);
            return (this.costAutoProlong = this.period.filter(
              i => i.k === this.item.autoprolong_period
            )[0].cost);
          } else if (this.isAutoProlong && this.item.autoprolong_period === 'null') {
            // console.log('test OK');
            // console.log(this.isAutoProlong, this.item.autoprolong_period);
            // console.log(this.period[0].cost);
            return (this.costAutoProlong = this.period[0].cost);
          }
        } else {
          throw new Error(this.$t('prolong.error'));
        }
      });
    },
    getCostDomainNotProlong() {
      if (this.isAutoProlong) {
        return (this.costAutoProlong = this.item.cost);
      }
    },
  },
};
