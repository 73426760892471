import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import ChangePricelistBlock from '@/components/ChangePricelist/ChangePricelistBlock';
import ChangePricelistConfirm from '@/components/ChangePricelist/ChangePricelistConfirm';
import Vue from 'vue';
export default {
  mixins: [billMgrToolActions],
  data() {
    return {
      cpm_pricelistsAvailable: [],
      cpm_model: null,
      cpm_wizard: null,
      cpm_modal: [null, null],
      cpm_currentPricelist: null,
      cpm_step: 0,
      func: '',
    };
  },
  computed: {
    pricelistsAll() {
      return this.$store.state[this.moduleMain][this.moduleOrder].list;
    },
    cpm_list() {
      return this.cpm_pricelistsAvailable.reduce((acc, i) => {
        const item = this.pricelistsAll.find(p => p.id == i.k);
        if (item) acc.push(item);
        return acc;
      }, []);
    },
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    typeOrg() {
      return this.$store.state.moduleProfile.payers.type;
    },
  },
  watch: {
    cpm_currentPricelist: function (val) {
      if (this.cpm_modal[0]) {
        Vue.set(this.cpm_modal[0].footer.confirm.props, 'disabled', !val);
      }
    },
  },
  mounted() {
    // console.log(this.typeOrg);
    if (!this.typeOrg && this.access['customer.profile']) {
      this.$store.dispatch('moduleProfile/fetchPayersInfo').catch(e => this.showError(e));
    }
  },
  methods: {
    changepricelist() {
      this.cpm_runStep0();
    },
    async cpm_runStep0() {
      this.$modals.open({
        name: 'Changepricelist_Step0',
        title: this.$t('changepricelist.title'),
        component: BaseLoader,
        hiddenOverflow: true,
        onOpen: inst => (this.cpm_modal[0] = inst),
        onClose: () => (this.cpm_modal[0] = null),
        footer: {
          confirm: {
            props: { title: this.$t('continue'), disabled: !this.cpm_currentPricelist },
            on: {
              click: () => {
                this.cpm_step = 1;
                this.cpm_runStep1();
              },
            },
          },
          cancel: {
            props: { title: this.$t('cancel') },
            on: { click: this.$modals.close },
          },
        },
      });
      if (!this.pricelistsAll.length) await this.cpm_fetchAllPricelists();
      this.cpm_fetchAvailablePricelists()
        .then(() => {
          Vue.set(this.cpm_modal[0], 'title', this.cpm_wizard[this.cpm_step].msg);
          if (this.cpm_list.length > 2) Vue.set(this.cpm_modal[0], 'size', 'big');
          Vue.set(this.cpm_modal[0], 'props', {
            list: this.cpm_list,
            // newFeatures: this.newFeatures,
          });
          Vue.set(this.cpm_modal[0], 'on', {
            change: val => (this.cpm_currentPricelist = val),
          });
          Vue.set(this.cpm_modal[0], 'component', ChangePricelistBlock);
        })
        .catch(() => {
          this.$modals.close({ name: this.cpm_modal[0].name });
        });
    },
    basket() {
      this.$root.$refs.HeaderBasket.openMore();
    },
    cpm_runStep1() {
      // console.log('cpm_runStep1');
      this.$modals.open({
        name: 'Changepricelist_Step1',
        title: this.cpm_wizard[this.cpm_step].msg,
        component: BaseLoader,
        onOpen: inst => (this.cpm_modal[1] = inst),
        onClose: () => (this.cpm_modal[1] = null),
        footer: {
          confirm: {
            props: { title: this.$t('actions.basket'), disabled: true },
            on: {
              click: () => {
                this.cpm_step = 2;
                this.cpm_runStep2();
              },
            },
          },
          cancel: {
            props: { title: this.$t('cancel') },
            on: {
              click: () => {
                this.cpm_step = 0;
                this.$modals.close();
              },
            },
          },
        },
      });
      this.cpm_confirmation().then(data => {
        if (data.ok && data.func === 'service.changepricelist') {
          // console.log(data);
          this.$modals.close();
          return this.basket();
          // const props = {
          //   // newFeatures: this.newFeatures,
          //   oldpricelist: model.oldpricelist || '',
          //   newpricelist: model.newpricelist || '',
          //   oldprice: model.oldprice,
          //   newprice: model.newprice,
          //   oldexpiredate: model.oldexpiredate,
          //   newexpiredate: model.newexpiredate,
          //   func: func,
          //   model: model,
          // };
          // Vue.set(this.cpm_modal[1], 'props', props);
          Vue.set(this.cpm_modal[1], 'title', this.cpm_wizard[this.cpm_step].msg);
          Vue.set(this.cpm_modal[1], 'component', ChangePricelistConfirm);
          Vue.set(this.cpm_modal[1].footer.confirm.props, 'disabled', false);
        }
        // console.log(this.cpm_wizard[this.cpm_step]);
        const { model, func } = data;
        this.func = func;
        if (model) {
          const props = {
            // newFeatures: this.newFeatures,
            oldpricelist: model.oldpricelist || '',
            newpricelist: model.newpricelist || '',
            oldprice: model.oldprice,
            newprice: model.newprice,
            oldexpiredate: model.oldexpiredate,
            newexpiredate: model.newexpiredate,
            func: func,
            model: model,
          };
          // console.log(props);
          Vue.set(this.cpm_modal[1], 'props', props);
          Vue.set(this.cpm_modal[1], 'title', this.cpm_wizard[this.cpm_step].msg);
          Vue.set(this.cpm_modal[1], 'component', ChangePricelistConfirm);
          Vue.set(this.cpm_modal[1].footer.confirm.props, 'disabled', false);
        }
      });
    },
    cpm_runStep2() {
      // console.log('cpm_runStep2');
      let modal = null;
      this.$modals.open({
        name: 'ChangePricelistResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.cpm_confirmation(true).then(data => {
        // console.log(data);
        if (data && data.ok) {
          this.cpm_reset();
          Vue.set(modal, 'text', this.$t('changepricelist.success'));
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'closable', true);
          Vue.set(modal, 'footer', {
            confirm: {
              props: { title: this.$t('good') },
              on: {
                click: () => {
                  this.$modals.close();
                  this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                },
              },
            },
          });
          if (this.cpm_modal[0]) this.$modals.close({ name: this.cpm_modal[0].name });
          this.$modals.close({ name: 'ChangePricelistResult' });
          this.$store.dispatch('moduleBasket/fetchBasket').then(() => this.basket());
        } else {
          // console.log(data);
          this.$modals.close({ name: 'ChangePricelistResult' });
        }
      });
    },
    cpm_fetchAllPricelists() {
      return this.$store.dispatch(`${this.moduleMain}/${this.moduleOrder}/fetchPricelist`, {
        itemtype: this.itemtype,
      });
    },
    cpm_fetchAvailablePricelists() {
      const params = {
        func: this.tools.changepricelist.func,
        id: this.tariff.id,
      };
      return this.fetchBillMgrAction(params).then(data => {
        if (data.slist && data.slist.pricelist) {
          this.cpm_pricelistsAvailable = data.slist.pricelist;
        }
        if (data.model) this.cpm_model = data.model;
        if (data.wizard) this.cpm_wizard = data.wizard;
      });
    },
    cpm_confirmation() {
      // console.log('cpm_confirmation');
      const params = {
        ...this.cpm_model,
        pricelist: this.cpm_currentPricelist,
        func: this.func ? this.func : this.cpm_wizard[this.cpm_step - 1].name,
      };
      delete params.id;
      if (params.func === 'service.changepricelist.getmoney') {
        delete params.snext;
        params.clicked_button = 'basket';
      }
      // console.log('216', params);
      return this.sendBillMgrAction(params);
    },
    cpm_reset() {
      this.cpm_step = 0;
      this.$modals.close({ name: this.cpm_modal[1].name });
      this.$modals.close({ name: this.cpm_modal[0].name });
      this.cpm_currentPricelist = null;
    },
  },
};
